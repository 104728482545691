<template>
  <NuxtLayout name="base">
    <div class="flex flex-col min-h-dvh">
      <AppHeader sticky />
      <div class="flex-grow min-w-0 container mx-auto p-4 sm:p-6 lg:p-8">
        <slot />
      </div>
      <AppFooter />
    </div>
  </NuxtLayout>
</template>
